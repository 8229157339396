<template>
  <div
    v-if="!isLoading"
    id="misc"
  >
    <img
      class="misc-mask"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <WstIzq
      v-if="name_project === 'wwwww'"
      class="misc-tree"
      width="230"
      height="200"
      :color="$vuetify.theme.themes.light.primary"
    />
    <ProIzq
      v-if="name_project === 'ppppp'"
      class="misc-tree"
      width="230"
      height="200"
      :color="$vuetify.theme.themes.light.primary"
    />

    <KroIzq
      v-if="name_project === 'kkkkk'"
      class="misc-tree"
      width="230"
      height="160"
      :color="$vuetify.theme.themes.light.primary"
    />

    <TravelzunIzq
      v-if="name_project === 'ttttt'"
      class="misc-tree"
      width="230"
      height="160"
      :color="$vuetify.theme.themes.light.primary"
    />

    <div class="page-title text-center px-5">
      <h2 class="text-2xl font-weight-semibold text--primary">
        Bienvenido a {{ config.name }}!!!
      </h2>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
          offset-md="3"
        >
          <p class="text-sm d-flex justify-center">
            Ha concluido el proceso de registro. Ahora puede iniciar sesión y comenzar a gestionar el perfil de su empresa.
          </p>
        </v-col>
      </v-row>

      <div class="misc-character d-flex justify-center pt-0 mt-0">
        <v-img
          max-width="500"
          src="@/assets/images/3d-characters/under-maintenance.png"
        ></v-img>
      </div>

      <v-btn
        color="primary"
        :to="{ name: 'auth-login' }"
        class="mb-4"
      >
        INICIAR SESIÓN
      </v-btn>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import ProIzq from '@/views/utils/images/pro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import KroIzq from '@/views/utils/images/kro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstIzq from '@/views/utils/images/wst_izq.vue'

export default {
  components: {
    ProIzq,
    KroIzq,
    WstIzq,
  },
  data() {
    return {
      config: {
        logo: null,
        name: '',
      },
      isLoading: true,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      loading: false,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.axios
        .get('configuration-view/data-general')
        .then(res => {
          if (res.data.success) {
            this.config = res.data.data.data.data

            this.$vuetify.theme.themes.light.primary = this.config.color
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
